import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import i18n, { loadLanguageAsync } from './plugins/i18n'
// import 'element-ui/lib/theme-chalk/index.css'
import echarts from 'echarts'
// ElementUI 全局组件注入位置
import Element from './plugins/element'
import './plugins/element/index.scss'
import eqxUI from '@eqxiu/eqx-max-ui'
import '@eqxiu/eqx-max-ui/lib/theme/index.css'
import env from './config/env'
import { getUrlParam } from '@/utils/url'
// 全局组件注入
import components from './components/global'
// EventBus
import EventBus from './utils/eventBus'
import { $loadEcmpCommons } from '@/utils/loader'
import process from 'process'
// 全局注入vue filters
import './components/filters'
env.initHost()

// 工作台整体嵌入时，保存secretId可以全局访问
const corpSecretId = getUrlParam('secretId')

if (corpSecretId) {
    sessionStorage.setItem('corpSecretId', corpSecretId)
} else {
    sessionStorage.removeItem('corpSecretId')
}

// 修复minio无法拿到正确的process
if (window.process) {
    Object.assign(window.process, process)
} else {
    window.process = process
}

Vue.prototype.$echarts = echarts

window.Vue = Vue
Vue.prototype.$eventBus = EventBus

let directives
let dialog
export function createApp(elementOrSelector, options, { install } = {}) {
    Vue.config.productionTip = false
    Vue.use(eqxUI)
    Vue.use(Element)
    Vue.use(VueCompositionAPI)
    Vue.use(directives)
    Vue.prototype._env = env
    Vue.use(components)
    Vue.use(dialog)
    if (install) {
        Vue.use(install)
    }
    return new Vue(options).$mount(elementOrSelector)
}

function createEncryptPromise() {
    if (window.eqxEncrypt) {
        return $loadEcmpCommons().then(() => Commons.loadAndRunGoWasm())
    }
    return Promise.resolve()
}

/**
 * 先加载语言包，再动态加载其他文件
 */
function createAppAsync() {
    loadLanguageAsync().then(() => {
        Promise.all([
            import('./App.vue'),
            import('./config/router.js'),
            import('./store/index.js'),
            import('@/plugins/newDialog'),
            import('./directives'),
            import('./plugins'),
            import('./plugins/base'),
            import('./plugins/user'),
            import('./utils'),
            import('./config/http.js')
        ]).then(([App, router, store, newDialog, direct]) => {
            dialog = newDialog.default
            directives = direct.default
            createApp('#app', {
                router: router.default,
                store: store.default,
                i18n,
                render: h => h(App.default)
            })
        })
    })
}

createEncryptPromise()
    .then(() => {
        createAppAsync()
    })
    .catch(err => console.error('decrypt error', err))
